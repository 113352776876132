import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AgentList from "../components/agent-list"
// import AgentHeaderImg from "../images/agent-header.jpg"

const Management = ({ data, location }) => {
  const allPosts = data.allAgent.nodes

  return (
    <Layout>
      <Seo title="Management" location={location} />
      <div
        className="agent-list-header"
      >
        <h1 className="h1-header">
          Management Team
        </h1>
      </div>

      <AgentList agentList={allPosts} />
    </Layout>
  )
}

export default Management

export const query = graphql`
  query MyLeadershipQuery {
    allAgent(filter: {isLeadership: {eq: "true"}}) {
      nodes {
        firstName
        lastName
        fullName
        prefferedPhone
        officePhone
        mobilePhone
        email
        city
        address1
        address2
        officeName
        MemberMlsId
        path
        mlsId
        photo {
          url
        }
        title
        agentBio
      }
    }
  }
`
